<template>
    <v-card class="pa-4">
        <h1>Пользователи</h1>
        <v-data-table :loading="loading" :headers="headers" :items="users" search multi-sort>
        </v-data-table>
    </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default {
    data(){
        return{
            headers: [
            {
                text: 'Id',
                value: 'id',
            },
            { text: 'Имя', value: 'name' },
            { text: 'Телефон', value: 'phone' }
            ],
            loading: false
        }
    },
    computed: mapGetters(['users']),
    methods: mapActions(['getUsers']),
    async mounted(){
      this.loading = true;
      await this.getUsers();
      this.loading = false;  
    },
}
</script>