<template>
    <v-card class="pa-4">
        <h1>Настройка сайта</h1>
        <v-card elevation="0">
            <h2>Дата закрытия регистрации</h2>
            <v-menu 
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field :loading="loading"
                        v-model="dateInput"
                        label="Дата"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dateCalendar"
                    @input="menu = false"
                ></v-date-picker>
                </v-menu>
            <v-btn color="blue darken-4" class="white--text" :loading="loading" @click="saveRegistrationDate">Сохранить</v-btn>
        </v-card>
    </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default {
    data(){
        return {
            dateCalendar: null,
            dateInput: null,
            dateTimeStamp: null,
            menu: false,
            loading: false
        }
    },
    computed: {
        ...mapGetters(['dueDateStatus', 'registrationDueDate']),
        computedDateFormattedInput () {
            return this.formatDateInput(this.dateTimeStamp)
        },
        computedDateFormattedCalendar () {
            return this.formatDateCalendar(this.dateTimeStamp)
        },
    },
    watch:{
        registrationDueDate: function(val){
            console.log(val);
            this.dateTimeStamp = val;
            this.dateCalendar = this.formatDateCalendar(val);
            this.dateInput = this.formatDateInput(val);
        },
        dateCalendar: function(val){
            console.log(val);
            this.dateTimeStamp = this.parseDateCalendar(val);
            console.log(this.dateTimeStamp);
            this.dateInput = this.formatDateInput(this.dateTimeStamp);
        }
    },
    async mounted(){
        if(this.dueDateStatus !== "OK"){
            this.loading = true;
            await this.fetchRegistrationDueDate();
            this.loading = false;
        }
    },
    methods: {
        ...mapActions(['fetchRegistrationDueDate', 'setRegistrationDueDate']),
        formatDateInput (date) {
            if (!date) return null
            const dateTime = new Date(date * 1000);
            const [year, month, day] = [dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate()]
            return `${day}.${month}.${year}`
        },
        formatDateCalendar (date) {
            if (!date) return null
            const dateTime = new Date(date * 1000);
            const [year, month, day] = [dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate()]
            return `${year}-${month}-${day}`
        },
        parseDateCalendar (date) {
            if (!date) return null
            const [year, month, day] = date.split('-');
            console.log(new Date(year, month, day) / 1000 | 0);
            return new Date(year, month, day) / 1000 | 0;
        },
        async saveRegistrationDate(){
            this.loading = true;
            await this.setRegistrationDueDate(this.dateTimeStamp);
            this.loading = false;
        }
    },
  }
</script>