<template>
    <v-container :v-show="adminLoginStatus === 'OK'">
        <v-row>
            <v-col cols="12" md="6">
                <h1>Административная панель</h1>
            </v-col>
            <v-col cols="12" md="6">
                <v-btn color="blue darken-4" class="white--text d-block mr-0 ml-auto" @click="exit">Выйти</v-btn>
            </v-col>
        </v-row>
        <v-row class="mt-2">
            
            <v-col cols="12" md="6">
                <DueDate/>
            </v-col>
            <v-col cols="12" md="6">
                <Users/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import DueDate from "@/components/Admin/DueDate"
import Users from "@/components/Admin/Users"
export default {
    title: 'VBall - администратор',
    components:{
        DueDate, Users
    },
    computed: mapGetters(['adminLoginStatus']),
    methods:{
        ...mapActions(['enableHeader', 'enableVideo', 'enableFooter', 'logout']),
        exit(){
            this.logout();
        }
    },
    watch:{
        adminLoginStatus: function(val){
            if(val !== "OK"){
                window.location.href = 'login'
            }
        }
    },
    mounted(){
        if(this.adminLoginStatus !== "OK"){
            window.location.href = 'login'
        }
    },
    beforeMount(){
        this.enableHeader(false);
        this.enableVideo(false);
        this.enableFooter(false);  

    },
    beforeDestroy(){
        this.enableHeader(true);
        this.enableVideo(true);
        this.enableFooter(true);
    }
}
</script>